.main {
  margin-top: 30px;
}

.ui.label.ongoing {
  background-color: #00b5ad;
  color: white;
}

.ui.label.cancelled {
  background-color: #f2921c;
  color: white;
}

.ui.label.expired {
  background-color: #767676;
  color: white;
}

.ui.label.approved {
  background-color: #21ba45;
  color: white;
}

.ui.label.ended {
  background-color: #2185d0;
  color: white;
}

.ui.label.pending {
  background-color: #00b5ad;
  color: white;
}

.ui.label.authorized {
  background-color: #21ba45;
  color: white;
}

.ui.label.failed {
  background-color: #dd4b39;
  color: white;
}

.ui.label.cancelled {
  background-color: #f2921c;
  color: white;
}

.ui.label.preAuthPostponed {
  background-color: #2185d0;
  color: white;
}

.ui.label.preAuthExpired {
  background-color: #a8a8a8;
  color: rgb(61, 61, 61);
}

.ui.label.found {
  background-color: #f2921c;
  color: white;
}

.ui.label.made {
  background-color: #f2921c;
  color: white;
}

.ui.label.archived {
  background-color: #2185d0;
  color: white;
}

.ui.label.verified {
  background-color: #21ba45;
  color: white;
}

.ui.label.discarded {
  background-color: #dd4b39;
  color: white;
}
.ui.segment.damage {
  background-color: #efefef;
  border: #f2efea00;
  color: black;
}
.ui.attached.segment.damageTabs {
  border: #efece800;
  border-color: rgb(200, 32, 32);
  border-width: 0;
  border-radius: 15;
  background-color: white;
}
.ui.label.admin {
  background-color: #00b5ad;
  color: white;
}

.ui.label.suspension {
  background-color: #db2828;
  color: white;
}
.ui.label.admin {
  background-color: #00b5ad;
  color: white;
}

.ui.label.suspension {
  background-color: #db2828;
  color: white;
}

.ui.form.short input[type=date] {
  width: unset;
}

table.selectable tbody tr {
  cursor: pointer;
}

.ui.label.ui.dropdown.button.icon {
  padding: .5833em .833em;
  align-items: center;
  display: flex;
}

.ui.form .react-datetime-picker__form-field {
  padding-left: .5em;
  padding-right: .5em;
}

div .react-datetime-picker__wrapper {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0 .7em 0 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  max-width: 100%;
  transition: box-shadow .1s ease, border-color .1s ease;
}

div .react-datetime-picker__inputGroup {
  display: flex;
  align-items: center;
  height: 36px;
}

.ui.form .react-datetime-picker__inputGroup__input {
  padding-top: 1px !important;
  padding-right: 1px !important;
  padding-bottom: 1px !important;
  padding-left: 1px !important;
  background: none !important;
  height: calc(100% - 2px) !important;
  appearance: textfield !important;
  -webkit-appearance: textfield !important;
  border: 0 none !important;
}

.ui.form .react-datetime-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em !important;
  padding-left: calc(1px + 0.54em) !important;
}

.ui.form select.react-datetime-picker__inputGroup__input {
  width: auto;
}

.ui.form .react-datetime-picker__inputGroup__input:focus:not(.react-datetime-picker__inputGroup__amPm:focus) {
  border-color: #85b7d9 !important;
}

.ui.form .react-datetime-picker__inputGroup__amPm {
  height: calc(100% - 2px) !important;

}

.react-datetime-picker__inputGroup__input:focus-visible {
  outline-style: solid;
  outline-color: #85b7d9;
  outline-width: 1px;
}

.react-datetime-picker--disabled {
  opacity: .45;
}